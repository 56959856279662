<template>
  <!-- Step component for showing current step number -->
  <!-- The StepComponent receives the current step number and header text as props -->
  <StepComponent
    :currentStepNumber="currentStepNumber"
    :headerText="headerText"
  />

  <!-- Main Page Content -->
  <div class="page-content">
    <!-- Wrapper for the form and content, styled using a specific class for layout -->
    <div class="form-v2-content nominee-page">
      <!-- Left Section with an Image and Introductory Text -->
      <div class="form-left">
        <!-- Display an image from a URL stored in the 'formImageUrl' variable -->
        <img :src="formImageUrl" alt="form" class="img-fluid" />
      </div>

      <!-- Nominee Registration Form -->
      <!-- Form is handled locally with `@submit.prevent` to prevent page reload -->
      <form
        class="form-detail"
        method="POST"
        enctype="multipart/form-data"
        action="javascript:void(0)"
        @submit.prevent="handleSubmit"
        id="myform"
      >
        <!-- Form Title -->
        <h3 class="heading-title">Nominee Setup</h3>

        <!-- Introductory Paragraph for User Guidance -->
        <div class="site-paragraph">
          <blockquote>
            To ensure the security and proper management of your account, please
            provide accurate details for your nominee. The nominee information
            will be used in case of unforeseen circumstances. Make sure the
            nominee's details are complete and up-to-date to facilitate a smooth
            account management process in the future.
          </blockquote>
        </div>

        <!-- Form Body: Wrapper for all form inputs -->
        <div class="form-body">
          <div class="row face-image-section">
            <!-- Left Column for Nominee Details -->
            <div class="col-md-6">
              <!-- Nominee Name Input -->
              <div class="form-group">
                <label for="nomineeName">Nominee Name</label>
                <input
                  type="text"
                  v-model="nominee_name"
                  class="form-control"
                  required
                />
              </div>

              <!-- Relation with Nominee Input -->
              <div class="form-group">
                <label for="relationWithNominee">Relation With Nominee</label>
                <input
                  type="text"
                  v-model="nominee_relation"
                  class="form-control"
                  required
                />
              </div>

              <!-- Nominee NID (National ID) Input -->
              <div class="form-group">
                <label for="nomineeNID">Nominee NID</label>
                <input
                  type="text"
                  v-model="nominee_nid"
                  class="form-control"
                  required
                />
              </div>
            </div>

            <!-- Right Column for Nominee Image Upload -->
            <div class="col-md-6">
              <!-- Instruction to upload nominee's image -->
              <p>Nominee Image</p>

              <!-- Image Preview Section: Click to trigger file input -->
              <div @click="triggerFileInput('front')" class="image-preview">
                <img
                  :src="nidFrontImage"
                  alt="Face Preview"
                  class="img-fluid"
                />
              </div>

              <!-- Hidden File Input for Uploading Face Image -->
              <!-- Uses a reference for easy programmatic access -->
              <input
                type="file"
                ref="frontFileInput"
                accept="image/*"
                @change="handleFileUpload($event, 'front')"
                style="display: none"
              />
            </div>
          </div>
        </div>

        <!-- Form Navigation Buttons -->
        <div class="form-row-last">
          <div class="row">
            <!-- Back Button Section -->
            <div class="col-md-6 mb-3">
              <!-- Full-width back button for navigation -->
              <button
                type="button"
                class="backbutton btn btn-sm btn-block w-100"
                @click="goBack"
              >
                Back
              </button>
            </div>

            <!-- Next Button Section -->
            <div class="col-md-6">
              <!-- Full-width submit button with loading state -->
              <button
                type="submit"
                class="register btn btn-sm btn-block w-100"
                :class="{ disabled: isLoading }"
              >
                Next
                <!-- Spinner displayed when `isLoading` is true -->
                <div
                  v-if="isLoading"
                  class="spinner-border text-primary"
                  role="status"
                >
                  <span class="visually-hidden"></span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// Import the required modules and components
import axios from "axios"; // Import axios for HTTP requests
import { useToast } from "vue-toast-notification"; // Import toast notifications
import router from "./../router/index.js"; // Import router for navigation
import StepComponent from "./common/StepComponent"; // Import custom StepComponent

export default {
  name: "NomineeComponent", // Component name
  components: {
    StepComponent, // Register StepComponent for displaying step information
  },
  data() {
    return {
      currentStepNumber: 7, // Current step number for form progress
      headerText: "Nominee", // Header text displayed in the form
      nominee_name: "", // Nominee's name
      nominee_relation: "", // Relation to the nominee
      nominee_nid: "", // Nominee's National ID
      nidFrontImage: `${process.env.VUE_APP_BASE_URL}/assets/images/face-placeholder.png`, // Default placeholder for the face image
      isLoading: false, // State for loading spinner during async operations
    };
  },
  computed: {
    // Computed property to get the form image URL
    formImageUrl() {
      // URL for the form image, dynamically set using environment variables
      return `${process.env.VUE_APP_BASE_URL}/assets/images/nominee.png`;
    },
  },
  methods: {
    /**
     * Handle file upload for nominee image and preview it
     * @param {Event} event - The file change event
     * @param {string} side - Specifies the side (e.g., 'front') of the image being uploaded
     */
    handleFileUpload(event, side) {
      const file = event.target.files[0];
      // Check if the uploaded file is an image
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          // Set the preview for the uploaded image based on the side
          if (side === "front") {
            this.nidFrontImage = e.target.result;
            this.uploadNomineeImage(file);
          }
        };
        reader.readAsDataURL(file); // Read file as a data URL for preview
      } else {
        alert("Please upload a valid image file."); // Alert if the file is not an image
      }
    },

    /**
     * Trigger the hidden file input to upload the image when clicked
     * @param {string} side - Specifies the side (e.g., 'front') of the image being triggered
     */
    triggerFileInput(side) {
      // Trigger file input click for the corresponding side
      if (side === "front") {
        this.$refs.frontFileInput.click();
      }
    },

    /**
     * Asynchronously upload the nominee's image to the server
     */
    async uploadNomineeImage() {
      const $toast = useToast({ position: "top" }); // Initialize toast notifications

      // Check if the image is valid before uploading
      if (!this.nidFrontImage || this.nidFrontImage.includes("placeholder")) {
        $toast.error("Please upload a nominee image.");
        return;
      }

      // Prepare data for the image upload
      const data = {
        mobile: localStorage.getItem("mobile"), // Mobile number from localStorage
        otp: localStorage.getItem("otp"), // OTP from localStorage
        nominee_img: this.nidFrontImage.split(",")[1], // Base64-encoded image
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.VUE_APP_API_BASE_URL}/nominee-upload`, // Endpoint for nominee image upload
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data), // Data converted to JSON
      };

      try {
        this.isLoading = true; // Show loading spinner
        const response = await axios.request(config); // Make the POST request

        // Check the success status of the API response
        if (!response.data.success) {
          $toast.error(response.data.message); // Show error toast if failed
        }
      } catch (error) {
        $toast.error("Failed to upload nominee image."); // Error toast if request fails
      } finally {
        this.isLoading = false; // Hide loading spinner
      }
    },

    /**
     * Handle form submission for nominee details
     */
    async handleSubmit() {
      const $toast = useToast({ position: "top" }); // Initialize toast notifications

      // Prepare data for nominee details submission
      const data = {
        mobile: localStorage.getItem("mobile"), // Mobile number from localStorage
        otp: localStorage.getItem("otp"), // OTP from localStorage
        nominee_name: this.nominee_name,
        nominee_relation: this.nominee_relation,
        nominee_nid: this.nominee_nid,
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.VUE_APP_API_BASE_URL}/nominee-setup`, // API endpoint for nominee setup
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data), // Convert data to JSON
      };

      try {
        this.isLoading = true; // Show loading spinner
        const response = await axios.request(config); // Make the POST request

        // Check the success status of the API response
        if (response.data.success) {
          await this.submitAccountOpeningRequest(); // Proceed with account opening request
        } else {
          $toast.error(response.data.message); // Show error toast if failed
        }
      } catch (error) {
        $toast.error("Failed to submit account opening request."); // Error toast if request fails
      } finally {
        this.isLoading = false; // Hide loading spinner
      }
    },

    /**
     * Submit the account opening request using stored data
     */
    async submitAccountOpeningRequest() {
      const $toast = useToast({ position: "top" }); // Initialize toast notifications

      // Retrieve the customer form data from localStorage
      const storedData = localStorage.getItem("customerFormData");
      if (!storedData) {
        $toast.error("Failed to retrieve customer data."); // Error if data is not found
        return;
      }

      const formData = JSON.parse(storedData); // Parse the stored data

      // Prepare data for the account opening request
      const data = {
        mobile: localStorage.getItem("mobile"), // Mobile number from localStorage
        otp: localStorage.getItem("otp"), // OTP from localStorage
        email: formData.email,
        spouse: formData.spouse,
        present_address: formData.present_address,
        present_district_id: formData.present_district_id,
        profession_id: formData.profession_id,
        monthly_income: formData.monthly_income,
        source_of_income: formData.source_of_income,
        request_device: "WEB", // Source of the request
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.VUE_APP_API_BASE_URL}/account-opening-request`, // API endpoint for account opening
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data), // Convert data to JSON
      };

      try {
        this.isLoading = true; // Show loading spinner
        const response = await axios.request(config); // Make the POST request

        // Check the success status of the API response
        if (response.data.success) {
          router.push({ name: "CompleteComponent" }); // Navigate to the completion component
        } else {
          $toast.error(response.data.message); // Show error toast if failed
        }
      } catch (error) {
        $toast.error("Failed to submit account opening request."); // Error toast if request fails
      } finally {
        this.isLoading = false; // Hide loading spinner
      }
    },
    goBack() {
      // Redirect to OTP verification page
      router.push({
        name: "CustomerInformationComponent",
      });
    },
  },
};
</script>



<style scoped>
/* Scoped styles for the NomineeComponent */

/* Add margin to the form body for spacing */
.form-body {
  margin-top: 20px;
}

/* Style for the face image section */
.face-image-section {
  margin-bottom: 20px;
}
blockquote {
  font-size: 11px !important;
}

/* Style for the image preview area (clickable) */
.image-preview {
  margin-top: 10px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  max-height: 250px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Ensuring the image is responsive and fits within the preview area */
.image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>

<template>
  <!-- Step component to show current step number and header text -->
  <StepComponent
    :currentStepNumber="currentStepNumber"
    :headerText="headerText"
  />

  <!-- Main content section for the page -->
  <div class="page-content">
    <div class="form-v2-content compleate-page">
      <!-- Left Section: Displays image and form text -->
      <div class="form-left">
        <!-- Dynamically load form image -->
        <img :src="formImageUrl" alt="form" class="img-fluid" />
      </div>

      <!-- Account Opening Success Form -->
      <form class="form-detail" action="#" method="post" id="myform">
        <!-- Form Section Title -->
        <h3 class="heading-title">Opps! Page not Found</h3>

        <!-- Information paragraph explaining the success -->
        <div class="site-paragraph">
          <blockquote>
            Sorry, the page you are looking for does not exist. It might have
            been removed, had its name changed, or is temporarily unavailable.
          </blockquote>
        </div>

        <!-- Success Image Section -->
        <div class="success-image">
          <!-- Display success icon after account creation -->
          <img :src="successIcon" alt="Success Icon" />
        </div>
      </form>
    </div>
  </div>
</template>
  
  <script>
// Import necessary child component (StepComponent)
import StepComponent from "./common/StepComponent";

export default {
  name: "NotFoundComponent", // Component name for identification

  components: {
    StepComponent, // Registering the StepComponent
  },

  data() {
    return {
      // Data properties for the component
      currentStepNumber: 1, // Step number in the process (used in StepComponent)
      headerText: "SEND OTP", // Header text shown in StepComponent
      phone: "", // Placeholder for phone number input (if needed)
      errorMessage: "", // Placeholder for error messages
      isLoading: false, // Flag indicating loading state
      successIcon: `${process.env.VUE_APP_BASE_URL}/assets/images/404-image.jpg`, // Default success icon URL
    };
  },

  computed: {
    // Computed property to dynamically generate form image URL
    formImageUrl() {
      return `${process.env.VUE_APP_BASE_URL}/assets/images/404.png`; // Dynamic image URL
    },
  },
};
</script>
  
  <style scoped>
/* Styling for the success image container */
.success-image {
  display: flex; /* Flexbox for centering the image */
  align-items: center; /* Align vertically */
  justify-content: center; /* Center horizontally */
  margin-top: 30px; /* Add margin on top */
}
</style>
  
<template>
  <!-- Step component for showing current step number -->
  <StepComponent
    :currentStepNumber="currentStepNumber"
    :headerText="headerText"
  />

  <!-- Page Content -->
  <div class="page-content">
    <div class="form-v2-content account-type-page">
      <!-- Left Section with Image and Text -->
      <div class="form-left">
        <img :src="formImageUrl" alt="form" class="img-fluid" />
      </div>

      <!-- Registration Form -->
      <form
        class="form-detail"
        method="POST"
        action="javascript:void(0)"
        @submit.prevent="handleSubmit"
      >
        <h3 class="heading-title">Choose Account Type</h3>
        <!-- Introductory Paragraph -->
        <div class="site-paragraph">
          <blockquote>
            Using this application, you can open SBAC eKYC account opening.
            Please use a Bangladeshi mobile number to receive an OTP (One-Time
            Password) for verification.
          </blockquote>
        </div>

        <div class="form-body">
          <div class="row radio-button-text">
            <div class="col-md-12" id="account_code">
              <div class="form-group first">
                <!-- Radio Button for Simplified Account -->
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="customRadioInline1"
                    name="accountType"
                    value="simplified"
                    class="custom-control-input"
                    v-model="formData.account_type"
                    @change="handleAccountTypeChange"
                  />
                  <label
                    class="custom-control-label radio-label"
                    for="customRadioInline1"
                  >
                    Simplified Account
                  </label>
                </div>

                <!-- Radio Button for Regular Account -->
                <div class="custom-control custom-radio custom-control-inline">
                  <input
                    type="radio"
                    id="customRadioInline2"
                    name="accountType"
                    value="regular"
                    class="custom-control-input"
                    v-model="formData.account_type"
                    @change="handleAccountTypeChange"
                  />
                  <label
                    class="custom-control-label radio-label"
                    for="customRadioInline2"
                  >
                    Regular Account
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div
              class="col-md-12"
              v-if="formData.account_type === 'simplified'"
            >
              <div class="simplified-text">
                <p>* Deposit withdrawal not exceeding BDT 1 Lac per month</p>
                <p>* Term deposit up to BDT 1,000,000.00</p>
                <p>* DPS maturity value up to BDT 1,000,000.00</p>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="account_code">Account Type *</label>
            <select v-model="formData.account_code" class="form-control">
              <option value="">Select Account</option>
              <option
                v-for="account in filteredAccounts"
                :key="account.acc_code"
                :value="account.acc_code"
              >
                {{ account.acc_name }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="branch_code">Branch *</label>
            <select v-model="formData.branch_code" class="form-control">
              <option value="">Select Branch</option>
              <option
                v-for="branch in branches"
                :key="branch.branch_code"
                :value="branch.branch_code"
              >
                {{ branch.branch_name.toUpperCase() }}
              </option>
            </select>
          </div>
        </div>

        <!-- Submit and Modal Trigger Buttons -->
        <div class="form-row-last">
          <div class="row">
            <div class="col-md-6 mb-3">
              <!-- Back button -->
              <button
                type="button"
                class="backbutton btn btn-sm btn-block w-100"
                @click="goBack"
              >
                Back
              </button>
            </div>
            <div class="col-md-6">
              <!-- Next button -->
              <button
                type="submit"
                class="register btn btn-sm btn-block w-100"
                :class="{ disabled: isLoading }"
              >
                Next
                <div
                  v-if="isLoading"
                  class="spinner-border text-primary"
                  role="status"
                >
                  <span class="visually-hidden"></span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// Import necessary components and libraries
import axios from "axios"; // Import axios directly
import { useToast } from "vue-toast-notification";
import router from "./../router/index.js"; // Assuming your router configuration file
import StepComponent from "./common/StepComponent";

export default {
  name: "AccountTypeSetupComponent", // Component name
  components: {
    StepComponent,
  },
  data() {
    return {
      currentStepNumber: 2, // Initial step number
      headerText: "ACCOUNT SETUP",
      formData: {
        account_code: "",
        branch_code: "",
        account_type: "",
      },
      branches: [], // Array to hold branch data
      accounts: [], // Array to hold all account data
      filteredAccounts: [], // Array to hold filtered account data based on selected account type
      isLoading: false, // To manage loading state
    };
  },
  computed: {
    formImageUrl() {
      return `${process.env.VUE_APP_BASE_URL}/assets/images/account-type-setup.png`;
    },
  },
  created() {
    // Fetch account and branch data when component is created
    this.fetchBranches();
    this.fetchAccounts();
  },
  methods: {
    async handleSubmit() {
      const $toast = useToast({ position: "top" });
      const data = {
        mobile: localStorage.getItem("mobile"),
        otp: localStorage.getItem("otp"),
        account_code: this.formData.account_code.toString(),
        branch_code: this.formData.branch_code.toString(),
        account_type: this.formData.account_type === "simplified" ? 1 : 0,
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.VUE_APP_API_BASE_URL}/account-type-setup`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      try {
        this.isLoading = true;
        const response = await axios.request(config);
        if (response.data.success) {
          router.push({ name: "NidUploadComponent" });
        } else {
          $toast.error(response.data.message);
        }
      } catch (error) {
        $toast.error("Failed to submit form data");
      } finally {
        this.isLoading = false;
      }
    },

    async fetchBranches() {
      const $toast = useToast({ position: "top" });
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/branch-list`
        );
        if (response.data.success) {
          this.branches = response.data.data;
        } else {
          $toast.error(response.data.message);
        }
      } catch (error) {
        $toast.error("Error fetching branches");
      }
    },

    async fetchAccounts() {
      const $toast = useToast({ position: "top" });
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/account-type-list`
        );
        if (response.data.success) {
          this.accounts = response.data.data;
        } else {
          $toast.error(response.data.message);
        }
      } catch (error) {
        $toast.error("Error fetching accounts");
      }
    },

    filterAccounts() {
      if (this.formData.account_type === "simplified") {
        this.filteredAccounts = this.accounts.filter(
          (account) => account.acc_type === 1
        );
      } else if (this.formData.account_type === "regular") {
        this.filteredAccounts = this.accounts.filter(
          (account) => account.acc_type !== 1
        );
      } else {
        this.filteredAccounts = this.accounts;
      }
    },

    handleAccountTypeChange() {
      this.filterAccounts();
    },
    goBack() {
      // Redirect to OTP verification page
      router.push({
        name: "OtpVerifyComponent",
      });
    },
  },
};
</script>

<style scoped>
div#account_code {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styles for radio buttons */
.radio-button-text {
  margin-bottom: 20px;
}

.custom-control-input:checked ~ .custom-control-label {
  font-weight: bold;
}

.radio-label {
  font-size: 18px;
  font-weight: bold;
}
</style>

<template>
  <!-- Step component for showing current step number -->
  <StepComponent
    :currentStepNumber="currentStepNumber"
    :headerText="headerText"
  />

  <!-- Page Content -->
  <div class="page-content">
    <div class="form-v2-content signature-page">
      <!-- Left Section with Image and Text -->
      <div class="form-left">
        <img :src="formImageUrl" alt="form" class="img-fluid" />
      </div>

      <!-- Registration Form -->
      <form class="form-detail" action="#" method="post" id="myform">
        <h3 class="heading-title">Account Signature</h3>
        <!-- Introductory Paragraph -->
        <div class="site-paragraph">
          <blockquote>
            Please upload a clear signature image for account opening. This will
            allow us to verify your signature for further processing. Ensure the
            image is exactly
            <strong>300 x 80 pixels (width x height)</strong> for accurate
            verification.
          </blockquote>
        </div>

        <div class="form-body">
          <!-- Signature Image Upload Section -->
          <div class="row face-image-section">
            <!-- Centered Signature Image Section -->
            <div class="col-md-6 offset-md-3">
              <p class="image-heading">Signature (300 X 80px)</p>
              <div @click="triggerFileInput('front')" class="image-preview">
                <img
                  :src="signaturePreviewImage || selfiePlaceholderImage"
                  alt="Signature Preview"
                  class="signature-image"
                />
              </div>
              <!-- Hidden file input for Signature Image -->
              <input
                type="file"
                ref="frontFileInput"
                id="signature_image"
                accept="image/*"
                @change="handleFileUpload($event)"
                style="display: none"
              />
            </div>
          </div>
        </div>

        <!-- Submit and Navigation Buttons -->
        <div class="form-row-last">
          <div class="row">
            <div class="col-md-6 mb-3">
              <!-- Back button - full width within half the row -->
              <button
                type="button"
                class="backbutton btn btn-sm btn-block w-100"
                @click="goBack"
              >
                Back
              </button>
            </div>
            <div class="col-md-6">
              <!-- Next button - full width within half the row -->
              <button
                type="button"
                class="register btn btn-sm btn-block w-100"
                @click="handleSubmit"
                :class="{ disabled: isLoading }"
              >
                Next
                <div
                  v-if="isLoading"
                  class="spinner-border text-primary"
                  role="status"
                >
                  <span class="visually-hidden"></span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toast-notification";
import router from "./../router/index.js"; // Assuming your router configuration file
import StepComponent from "./common/StepComponent";

export default {
  name: "SignatureComponent",
  components: {
    StepComponent,
  },
  data() {
    return {
      currentStepNumber: 5,
      headerText: "Signature",
      signaturePreviewImage: null, // Image preview state
      selfiePlaceholderImage: `${process.env.VUE_APP_BASE_URL}/assets/images/signature-placeholder.png`,
      isLoading: false,
    };
  },
  computed: {
    formImageUrl() {
      return `${process.env.VUE_APP_BASE_URL}/assets/images/signature.png`;
    },
  },
  methods: {
    handleFileUpload(event) {
      const $toast = useToast({ position: "top" });
      const file = event.target.files[0];
      if (file) {
        // Validate if the file is an image
        if (!file.type.startsWith("image/")) {
          $toast.error("Please upload a valid image file.");
          return;
        }

        // Validate file size (maximum 1MB)
        const maxFileSize = 1 * 1024 * 1024; // 1MB in bytes
        if (file.size > maxFileSize) {
          $toast.error("File size exceeds 1MB. Please upload a smaller image.");
          return;
        }

        // Create a new Image to check dimensions
        const img = new Image();
        const reader = new FileReader();
        reader.onload = (e) => {
          img.onload = () => {
            if (img.width !== 300 || img.height !== 80) {
              $toast.error(
                "Please upload a signature image with dimensions 300x80 pixels."
              );
              return;
            }
            // Preview the valid image
            this.signaturePreviewImage = e.target.result;
          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    async handleSubmit() {
      const $toast = useToast({ position: "top" });
      const fileInput = document.getElementById("signature_image");
      const file = fileInput ? fileInput.files[0] : null;

      if (!file) {
        $toast.error("Please select a signature image.");
        return;
      }

      const reader = new FileReader();
      reader.onload = async () => {
        const base64Image = reader.result.split(",")[1];

        const data = {
          mobile: localStorage.getItem("mobile"),
          otp: localStorage.getItem("otp"),
          sig_img: base64Image,
        };

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.VUE_APP_API_BASE_URL}/signature-upload`,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(data),
        };

        try {
          this.isLoading = true;
          const response = await axios.request(config);

          if (response.data.success) {
            this.$router.push({ name: "CustomerInformationComponent" });
          } else {
            $toast.error(response.data.message);
          }
        } catch (error) {
          $toast.error("Failed to process signature image");
        } finally {
          this.isLoading = false;
        }
      };

      reader.onerror = () => {
        $toast.error("Error reading the signature image.");
      };

      reader.readAsDataURL(file);
    },
    goBack() {
      // Redirect to OTP verification page
      router.push({
        name: "FaceVerificationComponent",
      });
    },

    triggerFileInput() {
      this.$refs.frontFileInput.click();
    },
  },
};
</script>

<style scoped>
.form-body {
  margin-top: 20px;
}

.face-image-section {
  margin-bottom: 20px;
}

.image-preview {
  margin-top: 10px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  max-height: 250px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

img.signature-image {
  width: 300px;
  height: 80px;
}
</style>

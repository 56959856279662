import { createApp } from 'vue';
import ToastPlugin from 'vue-toast-notification';
import App from './App.vue';
import router from './router'; // Adjust the path based on your file structure
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';


createApp(App)
  .use(router)
  .use(ToastPlugin)
  .mount('#app');

<template>
  <!-- Step component for showing current step number -->
  <StepComponent
    :currentStepNumber="currentStepNumber"
    :headerText="headerText"
  />

  <!-- Page Content -->
  <div class="page-content">
    <div class="form-v2-content ocr-data-review-page">
      <!-- Left Section with Image and Text -->
      <div class="form-left">
        <img :src="formImageUrl" alt="form" class="img-fluid" />
      </div>

      <!-- Registration Form -->
      <form
        class="form-detail"
        method="POST"
        enctype="multipart/form-data"
        action="javascript:void(0)"
        @submit.prevent="handleSubmit"
        id="myform"
      >
        <h3 class="heading-title">NID OCR Data Review</h3>
        <!-- Introductory Paragraph -->
        <div class="site-paragraph">
          <blockquote>
            Please review the NID OCR data carefully. If your NID number or date
            of birth appears to be incorrect, go back and upload your National
            ID card image again to ensure accurate information.
          </blockquote>
        </div>

        <div class="form-body">
          <!-- Employee ID Input -->
          <div class="form-group">
            <label for="employeeId">Your Name</label>
            <input type="text" v-model="name" disabled class="form-control" />
          </div>
          <div class="form-group">
            <label for="employeeId">NID Number</label>
            <input type="number" v-model="nid" disabled class="form-control" />
          </div>
          <div class="form-group">
            <label for="employeeId">Date Of Birth</label>
            <input type="text" v-model="dob" disabled class="form-control" />
          </div>
        </div>
        <!-- Submit and Modal Trigger Buttons -->
        <div class="form-row-last">
          <button type="button" class="backbutton btn btn-sm" @click="goBack">
            Back
          </button>
          <button type="submit" class="register btn btn-sm">
            Next
            <div
              v-if="isLoading"
              class="spinner-border text-primary"
              :class="{ disabled: isLoading }"
              role="status"
            >
              <span class="visually-hidden"></span>
            </div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
  
  <script>
// Import necessary components and libraries
import router from "./../router/index.js"; // Assuming your router configuration file
import StepComponent from "./common/StepComponent";

export default {
  name: "OcrDataReviewComponent", // Component name
  components: {
    StepComponent,
  },
  data() {
    // Initial component data
    return {
      currentStepNumber: 3, // Initial step number
      headerText: "OCR REVIEW",
      name: localStorage.getItem("english_name") || "",
      nid: localStorage.getItem("nid_number") || "",
      dob: localStorage.getItem("date_of_birth") || "",
    };
  },
  computed: {
    // Computed property for the form image URL
    formImageUrl() {
      // Make sure `process.env` is properly accessed
      return `${process.env.VUE_APP_BASE_URL}/assets/images/ocr-review.png`;
    },
  },
  methods: {
    handleSubmit() {
      router.push({ name: "FaceVerificationComponent" });
    },
    goBack() {
      // Redirect to OTP verification page
      router.push({
        name: "NidUploadComponent",
      });
    },
  },
};
</script>
  
  <style scoped>
/* Add your component-specific CSS here */
</style>
  
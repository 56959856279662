<template>
  <!-- Step component for showing the current step number and header -->
  <StepComponent
    :currentStepNumber="currentStepNumber"
    :headerText="headerText"
  />

  <!-- Page Content -->
  <div class="page-content">
    <div class="form-v2-content otp-verify-page">
      <!-- Left Section with Image and Text -->
      <div class="form-left">
        <!-- Display form image -->
        <img :src="otpVerifyImageUrl" alt="form" class="img-fluid" />
      </div>

      <!-- OTP Verification Form -->
      <form
        class="form-detail"
        id="myform"
        method="POST"
        enctype="multipart/form-data"
        action="javascript:void(0)"
        @submit.prevent="handleSubmit"
      >
        <!-- Title for the form section -->
        <h3 class="heading-title">OTP Verification</h3>

        <!-- Introductory Paragraph with information about the process -->
        <div class="site-paragraph">
          <blockquote>
            You can receive an OTP on the valid Bangladeshi number you provide.
            The OTP will expire after 3 minutes. If necessary, you can try again
            by clicking the "Resend OTP" button.
          </blockquote>
        </div>

        <div class="form-body">
          <!-- Mobile Number Display (Read-only) -->
          <div class="form-group">
            <!-- Label for Mobile Number -->
            <label for="mobile">Mobile Number *</label>
            <!-- Input field for displaying masked mobile number -->
            <input
              type="text"
              class="form-control"
              v-model="maskedMobile"
              readonly
            />
          </div>

          <!-- OTP Input Section -->
          <div class="form-group">
            <label for="otp">OTP *</label>
            <div class="otp-input-container">
              <!-- OTP input fields -->
              <input
                v-for="(digit, index) in 6"
                :key="index"
                type="text"
                class="form-control otp-input"
                ref="otpInput"
                maxlength="1"
                @input="handleOtpInput($event, index)"
                @keydown="disableArrowKeys"
                @paste.prevent
              />
            </div>
            <!-- Timer showing remaining time -->
            <p class="timer">
              Time remaining: {{ minutes }}:{{ formattedSeconds }}
            </p>
          </div>
        </div>

        <!-- Submit and Navigation Buttons -->
        <div class="form-row-last">
          <!-- Button to go back to the previous step -->
          <button type="button" class="backbutton btn btn-sm" @click="goBack">
            Back
          </button>
          <!-- Button to verify the OTP -->
          <button
            type="submit"
            class="register btn btn-sm"
            :class="{ disabled: isLoading }"
          >
            Verify OTP
            <div
              v-if="isLoading"
              class="spinner-border text-primary"
              role="status"
            >
              <span class="visually-hidden"></span>
            </div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
  
<script>
// Import the StepComponent to display step progress
import axios from "axios"; // Import axios directly
import { useToast } from "vue-toast-notification";
import router from "./../router/index.js"; // Assuming your router configuration file
import StepComponent from "./common/StepComponent";

export default {
  // Component name for identification
  name: "OtpVerifyComponent",

  // Register child components
  components: {
    StepComponent,
  },

  // Props to receive data from the parent component
  props: {
    mobile: String, // Prop to receive the mobile number
  },

  // Data properties for the component
  data() {
    return {
      currentStepNumber: 1, // Current step in the stepper component
      headerText: "OTP VERIFICATION", // Header text displayed in the StepComponent
      minutes: 3, // Timer minutes
      seconds: 0, // Timer seconds
      otp: "", // Holds the OTP input value
      errorMessage: "", // Holds error messages for the form
      isLoading: false, // Boolean flag to indicate loading state
    };
  },

  // Computed properties to handle dynamic values
  computed: {
    // Computed property to return the URL for the OTP verification image
    otpVerifyImageUrl() {
      // Ensure that the environment variable is properly accessed
      return `${process.env.VUE_APP_BASE_URL}/assets/images/otp-verify.png`;
    },

    // Computed property to mask the mobile number
    maskedMobile: {
      get() {
        // Mask the middle digits of the mobile number
        if (this.mobile && this.mobile.length === 11) {
          return this.mobile.replace(/^(\d{3})\d{6}(\d{2})$/, "$1******$2");
        }
        return this.mobile;
      },
    },

    // Computed property to format seconds with leading zero
    formattedSeconds() {
      return this.seconds < 10 ? `0${this.seconds}` : this.seconds;
    },
  },

  // Lifecycle hook called when the component is mounted
  mounted() {
    this.startTimer(); // Start the countdown timer when the component is mounted
  },

  // Methods to handle component behavior
  methods: {
    // Method to start the countdown timer
    startTimer() {
      const countdown = setInterval(() => {
        if (this.seconds === 0) {
          if (this.minutes === 0) {
            clearInterval(countdown);
            // Handle timer end logic
          } else {
            this.minutes--;
            this.seconds = 59;
          }
        } else {
          this.seconds--;
        }
      }, 1000);
    },

    // Handle OTP input and focus management
    handleOtpInput(event, index) {
      const otpInputs = this.$refs.otpInput || [];

      if (otpInputs.length === 0) {
        return;
      }

      // If the input is a digit and there is a next input, move focus to the next
      if (event.target.value.length === 1 && index < otpInputs.length - 1) {
        const nextInput = otpInputs[index + 1];
        if (nextInput) {
          nextInput.focus();
        }
      }
      // If the input is cleared, focus on the previous input
      else if (event.target.value.length === 0 && index > 0) {
        const prevInput = otpInputs[index - 1];
        if (prevInput) {
          prevInput.focus();
        }
      }

      // Update the OTP value by combining digits
      this.otp = otpInputs.map((input) => input.value).join("");
    },

    // Prevent arrow keys in OTP input fields
    disableArrowKeys(event) {
      if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
        event.preventDefault();
      }
    },

    async handleSubmit() {
      const $toast = useToast({ position: "top" });

      // Prepare data for the POST request
      const data = {
        mobile: this.mobile,
        otp: this.otp, // Use the updated OTP value here
      };

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.VUE_APP_API_BASE_URL}/verify-otp`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      try {
        this.isLoading = true; // Activate loading spinner
        const response = await axios.request(config);
        // Check response success flag
        if (response.data.success) {
          // Save mobile number and OTP in localStorage
          localStorage.setItem("mobile", this.mobile);
          localStorage.setItem("otp", this.otp);

          // Redirect to the NID Component
          router.push({ name: "AccountTypeSetupComponent" });
        } else {
          // Show error toast with message from API
          $toast.error(response.data.message);
        }
      } catch (error) {
        $toast.error("Failed to verify OTP");
      } finally {
        this.isLoading = false; // Deactivate loading spinner
      }
    },

    goBack() {
      // Redirect to OTP verification page
      router.push({
        name: "SendOtpComponent",
      });
    },
  },
};
</script>
  
<style scoped>
/* Scoped CSS for component styling */

/* OTP input container styling */
.otp-input-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

/* Individual OTP input field styling */
.otp-input {
  width: 40px !important;
  text-align: center;
  margin-right: 5px;
  border: none;
  border-radius: 5px !important;
  border-bottom: 2px solid #000;
  font-size: 24px;
}

/* Timer styling */
.timer {
  margin-top: 10px;
  font-size: 12px;
  color: red;
}
</style>

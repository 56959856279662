<template>
  <!-- Step component for showing current step number -->
  <StepComponent
    :currentStepNumber="currentStepNumber"
    :headerText="headerText"
  />

  <!-- Page Content -->
  <div class="page-content">
    <div class="form-v2-content page-verification-page">
      <!-- Left Section with Image and Text -->
      <div class="form-left">
        <img :src="formImageUrl" alt="form" class="img-fluid" />
      </div>

      <!-- Registration Form -->
      <form
        class="form-detail"
        method="POST"
        enctype="multipart/form-data"
        action="javascript:void(0)"
        @submit.prevent="handleSubmit"
        id="myform"
      >
        <h3 class="heading-title">Face Verification</h3>
        <!-- Introductory Paragraph -->
        <div class="site-paragraph">
          <blockquote>
            Please upload a clear face image for face verification with the
            national portal. Avoid using blurry or unrecognizable images to
            ensure accurate verification.
          </blockquote>
        </div>

        <div class="form-body">
          <!-- Face Image Upload Section -->
          <div class="row face-image-section">
            <!-- Centered Face Image Section -->
            <div class="col-md-6 offset-md-3">
              <p class="image-heading">Face Image</p>
              <div @click="triggerFileInput('front')" class="image-preview">
                <img
                  :src="nidFrontImage"
                  alt="Face Preview"
                  class="img-fluid"
                />
              </div>
              <!-- Hidden file input for Face Image -->
              <input
                type="file"
                ref="frontFileInput"
                accept="image/*"
                @change="handleFileUpload($event, 'front')"
                style="display: none"
              />
            </div>
          </div>
        </div>

        <!-- Submit and Navigation Buttons -->
        <div class="form-row-last">
          <div class="row">
            <div class="col-md-6 mb-3">
              <!-- Back button - full width within half the row -->
              <button
                type="button"
                class="backbutton btn btn-sm btn-block w-100"
                @click="handleBack"
              >
                Back
              </button>
            </div>
            <div class="col-md-6">
              <!-- Next button - full width within half the row -->
              <button
                type="submit"
                class="register btn btn-sm btn-block w-100"
                :class="{ disabled: isLoading }"
              >
                Next
                <div
                  v-if="isLoading"
                  class="spinner-border text-primary"
                  :class="{ disabled: isLoading }"
                  role="status"
                >
                  <span class="visually-hidden"></span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// Import necessary components and libraries
import axios from "axios";
import { useToast } from "vue-toast-notification"; // Import toast notification
import StepComponent from "./common/StepComponent";

export default {
  name: "FaceVerificationComponent", // Component name
  components: {
    StepComponent,
  },
  data() {
    // Initial component data
    return {
      currentStepNumber: 4, // Initial step number
      headerText: "Face Verification",
      isLoading: false, // Flag to indicate loading state
      nidFrontImage: `${process.env.VUE_APP_BASE_URL}/assets/images/face-placeholder.png`, // Default front image
    };
  },
  computed: {
    // Computed property for the form image URL
    formImageUrl() {
      return `${process.env.VUE_APP_BASE_URL}/assets/images/face-verification.png`;
    },
  },
  methods: {
    // Handle form submission
    async handleSubmit() {
      const $toast = useToast({ position: "top" }); // Use toast notifications

      // Check if the image is selected
      if (this.nidFrontImage.includes("face-placeholder")) {
        $toast.error("Please upload a customer face image.");
        return;
      }

      // Prepare data for the POST request
      const data = {
        mobile: localStorage.getItem("mobile"), // Retrieve mobile number from localStorage
        otp: localStorage.getItem("otp"), // Retrieve OTP from localStorage
        selfie_img: this.nidFrontImage.split(",")[1], // Base64 encoded image without prefix
      };

      let config = {
        method: "post", // HTTP POST method
        url: `${process.env.VUE_APP_API_BASE_URL}/selfie-upload`, // API endpoint URL
        headers: {
          "Content-Type": "application/json", // JSON content type header
        },
        data: JSON.stringify(data), // Convert data to JSON string
      };

      try {
        this.isLoading = true; // Activate loading spinner
        const response = await axios.request(config); // Send POST request

        // Check response success flag
        if (response.data.success) {
          this.$router.push({ name: "SignatureComponent" }); // Redirect to next component
        } else {
          $toast.error(response.data.message); // Display error message from API response
        }
      } catch (error) {
        $toast.error("Failed to upload the image."); // Error handling for API request failure
      } finally {
        this.isLoading = false; // Deactivate loading spinner
      }
    },

    // Handle file upload and preview
    handleFileUpload(event, side) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (side === "front") {
            this.nidFrontImage = e.target.result; // Base64 encoded image preview
          }
        };
        reader.readAsDataURL(file);
      }
    },

    // Trigger the hidden file input when the image preview is clicked
    triggerFileInput(side) {
      if (side === "front") {
        this.$refs.frontFileInput.click();
      }
    },

    // Handle back button click
    handleBack() {
      this.$router.go(-1); // Navigate back to the previous page
    },
  },
};
</script>

<style scoped>
/* Add your component-specific CSS here */
.form-body {
  margin-top: 20px;
}

.face-image-section {
  margin-bottom: 20px;
}

.image-preview {
  margin-top: 10px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  max-height: 250px;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>

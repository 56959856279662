<template>
  <!-- Form heading step section start -->
  <!-- Header Section -->
  <header>
    <nav class="navbar">
      <!-- Left Side: Image -->
      <div class="topbar logo-area">
        <div class="logo">
          <img :src="logoUrl" alt="SBAC Logo" />
        </div>
        <!-- Middle Section: Site Name -->
        <div class="site-name">SBAC Fast Account</div>
      </div>
      <div class="topbar stepform">
        <ul class="step">
          <li>
            <p class="step-count" :class="{ active: currentStepNumber >= 1 }">
              1
            </p>
            <p
              class="step-description"
              :class="{ active: currentStepNumber >= 1 }"
            >
              Phone Verification
            </p>
          </li>
          <li>
            <p class="step-count" :class="{ active: currentStepNumber >= 2 }">
              2
            </p>
            <p
              class="step-description"
              :class="{ active: currentStepNumber >= 2 }"
            >
              Account Type Setup
            </p>
          </li>
          <li>
            <p class="step-count" :class="{ active: currentStepNumber >= 3 }">
              3
            </p>
            <p
              class="step-description"
              :class="{ active: currentStepNumber >= 3 }"
            >
              NID
            </p>
          </li>
          <li>
            <p class="step-count" :class="{ active: currentStepNumber >= 4 }">
              4
            </p>
            <p
              class="step-description"
              :class="{ active: currentStepNumber >= 4 }"
            >
              Face Verification
            </p>
          </li>
          <li>
            <p class="step-count" :class="{ active: currentStepNumber >= 5 }">
              5
            </p>
            <p
              class="step-description"
              :class="{ active: currentStepNumber >= 5 }"
            >
              Signature
            </p>
          </li>
          <li>
            <p class="step-count" :class="{ active: currentStepNumber >= 6 }">
              6
            </p>
            <p
              class="step-description"
              :class="{ active: currentStepNumber >= 6 }"
            >
              Customer Information
            </p>
          </li>
          <li>
            <p class="step-count" :class="{ active: currentStepNumber >= 7 }">
              7
            </p>
            <p
              class="step-description"
              :class="{ active: currentStepNumber >= 7 }"
            >
              Nominee
            </p>
          </li>
        </ul>
      </div>
    </nav>
  </header>
  <!-- Form Heading step section end -->
</template>

<script>
export default {
  name: "StepComponent",
  props: {
    msg: String,
    currentStepNumber: Number, // Define prop for initial integer value
    headerText: String, // Define prop for initial string value
  },
  computed: {
    // Computed property to get the logo URL using environment variable
    logoUrl() {
      // Ensure that `process.env` is properly accessed
      return `${process.env.VUE_APP_BASE_URL}/assets/images/logo-full.png`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Add your component-specific CSS here */
</style>

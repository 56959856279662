import AccountTypeSetupComponent from '@/components/AccountTypeSetupComponent.vue';
import CompleteComponent from '@/components/CompleteComponent.vue';
import CustomerInformationComponent from '@/components/CustomerInformationComponent.vue';
import FaceVerificationComponent from '@/components/FaceVerificationComponent.vue';
import NidUploadComponent from '@/components/NidUploadComponent.vue';
import NomineeComponent from '@/components/NomineeComponent.vue';
import NotFoundComponent from '@/components/NotFoundComponent.vue';
import OcrDataReviewComponent from '@/components/OcrDataReviewComponent.vue';
import OtpVerifyComponent from '@/components/OtpVerifyComponent.vue';
import SendOtpComponent from '@/components/SendOtpComponent.vue';
import SignatureComponent from '@/components/SignatureComponent.vue';
import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'SendOtpComponent',
    component: SendOtpComponent
  },
  {
    path: '/otp-verify/:mobile',
    name: 'OtpVerifyComponent',
    component: OtpVerifyComponent,
    props: true, // Pass route params as props to component
  },
  {
    path: '/account-type-setup',
    name: 'AccountTypeSetupComponent',
    component: AccountTypeSetupComponent
  },
  {
    path: '/nid-ocr',
    name: 'NidUploadComponent',
    component: NidUploadComponent
  },
  {
    path: '/ocr-data-review',
    name: 'OcrDataReviewComponent',
    component: OcrDataReviewComponent
  },
  {
    path: '/face-verification',
    name: 'FaceVerificationComponent',
    component: FaceVerificationComponent
  },
  {
    path: '/signature',
    name: 'SignatureComponent',
    component: SignatureComponent
  },
  {
    path: '/customer-information',
    name: 'CustomerInformationComponent',
    component: CustomerInformationComponent
  },
  {
    path: '/nominee',
    name: 'NomineeComponent',
    component: NomineeComponent
  },
  {
    path: '/complete',
    name: 'CompleteComponent',
    component: CompleteComponent
  },
  // Use the new wildcard syntax
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFoundComponent',
    component: NotFoundComponent
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;

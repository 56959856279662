<template>
  <!-- Step component for showing the current step number and header -->
  <StepComponent
    :currentStepNumber="currentStepNumber"
    :headerText="headerText"
  />

  <!-- Page Content -->
  <div class="page-content">
    <div class="form-v2-content send-otp-page">
      <!-- Left Section with Image and Text -->
      <div class="form-left">
        <!-- Display form image -->
        <img :src="formImageUrl" alt="form" class="img-fluid" />
      </div>

      <!-- Registration Form -->
      <form
        class="form-detail"
        id="myform"
        method="POST"
        enctype="multipart/form-data"
        action="javascript:void(0)"
        @submit.prevent="handleSubmit"
      >
        <!-- Title for the form section -->
        <h3 class="heading-title">Send SMS For Mobile Verification</h3>

        <!-- Introductory Paragraph with information about the process -->
        <div class="site-paragraph">
          <blockquote>
            Using this application, you can open an SBAC eKYC account. Please
            use a Bangladeshi mobile number to receive an OTP (One-Time
            Password) for verification.
          </blockquote>
        </div>

        <div class="form-body">
          <!-- Mobile Number Input Section -->
          <div class="form-group">
            <!-- Label for Mobile Number Input -->
            <label for="employeeId">Mobile Number *</label>
            <!-- Input field bound to 'phone' data -->
            <input
              type="text"
              v-model="phone"
              class="form-control"
              placeholder="01XXXXXXXXX"
            />
            <!-- Error message display -->
            <p class="mb-4 error_text">
              <span class="error_message_show">{{ errorMessage }}</span>
            </p>
          </div>
        </div>

        <!-- Submit and Modal Trigger Buttons -->
        <div class="form-row-last">
          <!-- Button to send OTP -->
          <button
            type="submit"
            class="register btn btn-sm"
            :class="{ disabled: isLoading }"
          >
            Send OTP
            <div
              v-if="isLoading"
              class="spinner-border text-primary"
              role="status"
            >
              <span class="visually-hidden"></span>
            </div>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// Import the StepComponent to display step progress
import axios from "axios"; // Import axios directly
import { useToast } from "vue-toast-notification";
import router from "./../router/index.js"; // Assuming your router configuration file
import StepComponent from "./common/StepComponent";

export default {
  // Component name for identification
  name: "SendOtpComponent",

  // Register child components
  components: {
    StepComponent,
  },

  // Data properties for the component
  data() {
    return {
      currentStepNumber: 1, // Current step in the stepper component
      headerText: "SEND OTP", // Header text displayed in the StepComponent
      phone: "", // Holds the mobile number input value
      errorMessage: "", // Holds error messages for the form
      isLoading: false, // Boolean flag to indicate loading state
    };
  },
  methods: {
    // Method to handle form submission
    async handleSubmit() {
      const $toast = useToast({ position: "top" });

      // Validate phone number format
      if (this.validatePhone()) {
        this.errorMessage = "";
        this.isLoading = true; // Activate loading spinner

        // Prepare data for the POST request
        const data = {
          mobile: this.phone, // Phone number data
        };

        try {
          // Send POST request using axios
          const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/send-otp`,
            data
          );
          console.log(response);

          // Check if OTP sent successfully
          if (response.data.success) {
            // Redirect to OTP verification page
            router.push({
              name: "OtpVerifyComponent",
              params: { mobile: this.phone },
            });
          } else {
            // Show error toast with API response message
            $toast.error(response.data.message);
          }
        } catch (error) {
          console.error("Error:", error);
          $toast.error("Failed to send OTP"); // Error message for API failure
        } finally {
          this.isLoading = false; // Deactivate loading spinner
        }
      } else {
        this.errorMessage = "Invalid Bangladeshi mobile number."; // Error message for invalid phone number format
      }
    },

    // Method to validate Bangladeshi phone number format
    validatePhone() {
      const bangladeshPhonePattern = /^(?:\+88|88)?(01[3-9]\d{8})$/;
      return bangladeshPhonePattern.test(this.phone); // Return true if phone number matches pattern
    },
  },

  // Computed properties to handle dynamic values
  computed: {
    // Computed property to return the URL for the form image
    formImageUrl() {
      // Ensure that the environment variable is properly accessed
      return `${process.env.VUE_APP_BASE_URL}/assets/images/home-screen.png`;
    },
  },
};
</script>

<template>
  <!-- Step component for showing current step number -->
  <StepComponent
    :currentStepNumber="currentStepNumber"
    :headerText="headerText"
  />

  <!-- Page Content -->
  <div class="page-content">
    <div class="form-v2-content nid-upload-page">
      <!-- Left Section with Image and Text -->
      <div class="form-left">
        <!-- Display the form-related image -->
        <img :src="formImageUrl" alt="form" class="img-fluid" />
      </div>

      <!-- Registration Form for NID OCR Processing -->
      <form class="form-detail" @submit.prevent="handleSubmit" id="myform">
        <h3 class="heading-title">NID OCR Processing</h3>

        <!-- Introductory Paragraph for instructions -->
        <div class="site-paragraph">
          <blockquote>
            Please upload clear images of both the front and back of your
            National ID (NID) card. If the image is blurry or unclear, you won't
            be able to proceed to the next step. Please ensure that the images
            you upload are clear and legible.
          </blockquote>
        </div>

        <div class="form-body">
          <!-- NID Image Upload Section -->
          <div class="row nid-image-section">
            <!-- NID Front Image Section -->
            <div class="col-md-6">
              <p class="image-heading">NID Front Image</p>
              <div @click="triggerFileInput('front')" class="image-preview">
                <!-- Preview for NID Front Image -->
                <img
                  :src="nidFrontImage"
                  alt="NID Front Preview"
                  class="img-fluid"
                />
              </div>
              <!-- Hidden file input for NID Front -->
              <input
                type="file"
                ref="frontFileInput"
                accept="image/*"
                @change="handleFileUpload($event, 'front')"
                style="display: none"
              />
            </div>

            <!-- NID Back Image Section -->
            <div class="col-md-6">
              <p class="image-heading">NID Back Image</p>
              <div @click="triggerFileInput('back')" class="image-preview">
                <!-- Preview for NID Back Image -->
                <img
                  :src="nidBackImage"
                  alt="NID Back Preview"
                  class="img-fluid"
                />
              </div>
              <!-- Hidden file input for NID Back -->
              <input
                type="file"
                ref="backFileInput"
                accept="image/*"
                @change="handleFileUpload($event, 'back')"
                style="display: none"
              />
            </div>
          </div>

          <!-- Display error message if images are not uploaded correctly -->
          <div v-if="errorMessage" class="error-message text-danger">
            {{ errorMessage }}
          </div>
        </div>

        <!-- Submit and Navigation Buttons -->
        <div class="form-row-last">
          <div class="row">
            <div class="col-md-6 mb-3">
              <!-- Back button - occupies full width within half the row -->
              <button
                type="button"
                class="backbutton btn btn-sm btn-block w-100"
                @click="goBack"
              >
                Back
              </button>
            </div>
            <div class="col-md-6">
              <!-- Next button - occupies full width within half the row -->
              <button
                type="submit"
                class="register btn btn-sm btn-block w-100"
                :class="{ disabled: isLoading }"
              >
                Next
                <div
                  v-if="isLoading"
                  class="spinner-border text-primary"
                  role="status"
                >
                  <span class="visually-hidden"></span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// Import necessary components and libraries
import axios from "axios"; // Import axios for HTTP requests
import { useToast } from "vue-toast-notification"; // Import Toast notifications
import router from "./../router/index.js"; // Import router for navigation
import StepComponent from "./common/StepComponent"; // Import Step component

export default {
  name: "NidUploadComponent", // Define component name
  components: {
    StepComponent, // Register StepComponent as a sub-component
  },
  data() {
    return {
      currentStepNumber: 3, // Step number for the current page
      headerText: "NID", // Header text for the step component
      phone: "", // Phone number, if needed
      errorMessage: "", // Error message to display
      isLoading: false, // State flag for loading spinner
      // Default images for NID preview
      nidFrontImage: `${process.env.VUE_APP_BASE_URL}/assets/images/nid_front.png`,
      nidBackImage: `${process.env.VUE_APP_BASE_URL}/assets/images/nid_back.png`,
    };
  },
  computed: {
    // URL for form-related image
    formImageUrl() {
      return `${process.env.VUE_APP_BASE_URL}/assets/images/nid-ocr.png`;
    },
  },
  methods: {
    /**
     * Handle file upload and preview
     * @param {Event} event - File input change event
     * @param {String} side - 'front' or 'back' indicating which part of the NID
     */
    handleFileUpload(event, side) {
      const file = event.target.files[0];
      if (file) {
        // Validate file size (max 5MB)
        if (file.size > 5 * 1024 * 1024) {
          this.errorMessage = "File size should not exceed 5MB.";
          return;
        }

        // Validate file type (should be an image)
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = (e) => {
            if (side === "front") {
              this.nidFrontImage = e.target.result; // Update front image preview
              this.uploadFrontImage(file); // Upload the front image
            } else if (side === "back") {
              this.nidBackImage = e.target.result; // Update back image preview
              this.uploadBackImage(file); // Upload the back image
            }
            this.errorMessage = ""; // Clear any previous error messages
          };
          reader.readAsDataURL(file);
        } else {
          this.errorMessage = "Please upload a valid image file.";
        }
      }
    },
    /**
     * Trigger the hidden file input when the image preview is clicked
     * @param {String} side - 'front' or 'back' indicating which input to trigger
     */
    triggerFileInput(side) {
      if (side === "front") {
        this.$refs.frontFileInput.click();
      } else if (side === "back") {
        this.$refs.backFileInput.click();
      }
    },
    /**
     * Handle form submission for processing the NID OCR
     */
    async handleSubmit() {
      const $toast = useToast({ position: "top" });
      // Data to be sent in the POST request
      const data = {
        mobile: localStorage.getItem("mobile"),
        otp: localStorage.getItem("otp"),
      };
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.VUE_APP_API_BASE_URL}/nid-ocr-process`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };

      try {
        this.isLoading = true; // Show loading spinner
        const response = await axios.request(config);
        // Check API response
        if (response.data.success) {
          // If successful, navigate to the data review page
          const { english_name, date_of_birth, nid_number } =
            response.data.data;
          // Store retrieved data in local storage
          localStorage.setItem("english_name", english_name);
          localStorage.setItem("date_of_birth", date_of_birth);
          localStorage.setItem("nid_number", nid_number);
          // Redirect to data review component
          router.push({ name: "OcrDataReviewComponent" });
        } else {
          // Display error message if API fails
          $toast.error(response.data.message);
        }
      } catch (error) {
        // Display generic error message if the request fails
        $toast.error("Failed to verify OTP");
      } finally {
        this.isLoading = false; // Hide loading spinner
      }
    },
    /**
     * Upload the front image of the NID
     * @param {File} file - File to be uploaded
     */
    async uploadFrontImage(file) {
      const $toast = useToast({ position: "top" });
      this.isLoading = true; // Show loading spinner
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Image = reader.result.split(",")[1];
        const mobile = localStorage.getItem("mobile");
        const otp = localStorage.getItem("otp");

        const payload = {
          mobile,
          otp,
          nid_img_front: base64Image,
        };

        try {
          // Send POST request to upload the front image
          const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/nid-front-upload`,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          // Check API response for success or error
          if (!response.data.success) {
            $toast.error(response.data.message);
          }
        } catch (error) {
          // Display generic error message if upload fails
          $toast.error("Failed to upload NID front image");
        } finally {
          this.isLoading = false; // Hide loading spinner
        }
      };
      reader.readAsDataURL(file); // Read file as base64
    },
    /**
     * Upload the back image of the NID
     * @param {File} file - File to be uploaded
     */
    async uploadBackImage(file) {
      const $toast = useToast({ position: "top" });
      this.isLoading = true; // Show loading spinner
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Image = reader.result.split(",")[1];
        const mobile = localStorage.getItem("mobile");
        const otp = localStorage.getItem("otp");

        const payload = {
          mobile,
          otp,
          nid_img_back: base64Image,
        };

        try {
          // Send POST request to upload the back image
          const response = await axios.post(
            `${process.env.VUE_APP_API_BASE_URL}/nid-back-upload`,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          // Check API response for success or error
          if (!response.data.success) {
            $toast.error(response.data.message);
          }
        } catch (error) {
          // Display generic error message if upload fails
          $toast.error("Failed to upload NID back image");
        } finally {
          this.isLoading = false; // Hide loading spinner
        }
      };
      reader.readAsDataURL(file); // Read file as base64
    },
    goBack() {
      // Redirect to OTP verification page
      router.push({
        name: "AccountTypeSetupComponent",
      });
    },
  },
};
</script>

<style scoped>
/* Add your component-specific CSS here */
.form-body {
  margin-top: 20px;
}

.nid-image-section {
  margin-bottom: 20px;
}

.image-preview {
  margin-top: 10px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  height: auto;
  max-height: 250px;
  object-fit: cover;
  cursor: pointer; /* Makes the image look clickable */
}

.error-message {
  margin-top: 10px;
  font-size: 14px;
  color: red;
}
</style>
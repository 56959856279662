<template>
  <!-- Step component to show the current step number -->
  <StepComponent
    :currentStepNumber="currentStepNumber"
    :headerText="headerText"
  />

  <!-- Main page content container -->
  <div class="page-content">
    <div class="form-v2-content customer-information-page">
      <!-- Left section with an image illustration -->
      <div class="form-left">
        <img :src="formImageUrl" alt="Form Illustration" class="img-fluid" />
      </div>

      <!-- Account opening information form -->
      <form
        class="form-detail"
        id="customerForm"
        method="POST"
        enctype="multipart/form-data"
        action="javascript:void(0)"
        @submit.prevent="handleSubmit"
      >
        <!-- Form Header -->
        <h3 class="heading-title">Account Opening Information</h3>

        <!-- Instructions paragraph -->
        <div class="site-paragraph">
          <blockquote>
            Please fill out the account opening information carefully. Providing
            accurate and complete details will help ensure a smooth account
            setup process. Double-check all information, as any discrepancies
            may delay the account approval. Your details will be securely
            processed according to banking guidelines.
          </blockquote>
        </div>

        <!-- Form body section -->
        <div class="form-body">
          <div class="row">
            <!-- Email input field -->
            <div class="col-md-6">
              <div class="form-group">
                <label for="email">Email</label>
                <input
                  type="email"
                  name="email"
                  class="form-control"
                  v-model="formData.email"
                />
              </div>
            </div>

            <!-- Spouse name input field -->
            <div class="col-md-6">
              <div class="form-group">
                <label for="spouse">Spouse</label>
                <input
                  type="text"
                  name="spouse"
                  class="form-control"
                  v-model="formData.spouse"
                />
              </div>
            </div>

            <!-- Present address input field -->
            <div class="col-md-6">
              <div class="form-group">
                <label for="presentAddress">Present Address</label>
                <textarea
                  name="present_address"
                  class="form-control"
                  rows="1"
                  required
                  v-model="formData.present_address"
                ></textarea>
              </div>
            </div>

            <!-- Present district dropdown -->
            <div class="col-md-6">
              <div class="form-group">
                <label for="presentDistrict">Present District</label>
                <select
                  name="present_district_id"
                  class="form-control"
                  v-model="formData.present_district_id"
                  required
                >
                  <option value="">Select District</option>
                  <option
                    v-for="district in districts"
                    :key="district.id"
                    :value="district.id"
                  >
                    {{ district.name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Profession dropdown -->
            <div class="col-md-6">
              <div class="form-group">
                <label for="profession">Profession</label>
                <select
                  name="profession_id"
                  id="profession_id"
                  class="form-control"
                  v-model="formData.profession_id"
                  required
                >
                  <option value="">Select Profession</option>
                  <option
                    v-for="profession in professions"
                    :key="profession.profession_code"
                    :value="profession.profession_code"
                  >
                    {{ profession.profession_name }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Monthly income input field -->
            <div class="col-md-6">
              <div class="form-group">
                <label for="monthlyIncome">Monthly Income</label>
                <input
                  type="number"
                  name="monthly_income"
                  id="monthly_income"
                  class="form-control"
                  required
                  placeholder="Enter monthly income"
                  v-model="formData.monthly_income"
                />
              </div>
            </div>

            <!-- Source of fund input field -->
            <div class="col-md-6">
              <div class="form-group">
                <label for="sourceOfFund">Source of Fund</label>
                <input
                  type="text"
                  name="source_of_income"
                  class="form-control"
                  required
                  v-model="formData.source_of_income"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Form navigation buttons (Back and Next) -->
        <div class="form-row-last">
          <div class="row">
            <!-- Back button -->
            <div class="col-md-6 mb-3">
              <button
                type="button"
                class="backbutton btn btn-sm btn-block w-100"
                @click="goBack"
              >
                Back
              </button>
            </div>

            <!-- Submit (Next) button -->
            <div class="col-md-6">
              <button
                type="submit"
                class="register btn btn-sm btn-block w-100"
                :disabled="isLoading"
              >
                Next
                <div
                  v-if="isLoading"
                  class="spinner-border text-primary"
                  role="status"
                >
                  <span class="visually-hidden"></span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios"; // Import axios for HTTP requests
import { useToast } from "vue-toast-notification"; // Import vue-toast-notification
import router from "./../router/index.js"; // Import router
import StepComponent from "./common/StepComponent"; // Import custom step component

export default {
  name: "CustomerInformationComponent", // Component name
  components: {
    StepComponent, // Register the step component
  },
  data() {
    return {
      currentStepNumber: 6, // Current step in the process
      headerText: "CUSTOMER INFORMATION", // Header title
      isLoading: false, // Loading state for form submission
      // Data for form fields
      formData: {
        email: "",
        spouse: "",
        present_address: "",
        present_district_id: "",
        profession_id: "",
        monthly_income: "",
        source_of_income: "",
      },
      districts: [], // List of available districts
      professions: [], // List of available professions
    };
  },
  computed: {
    // Dynamically computed URL for the form image
    formImageUrl() {
      return `${process.env.VUE_APP_BASE_URL}/assets/images/customer-information.png`;
    },
  },
  created() {
    // Fetch required data on component creation
    this.fetchDistricts();
    this.fetchProfessions();
  },
  methods: {
    // Fetch districts from the API
    async fetchDistricts() {
      const $toast = useToast({ position: "top" });
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/district-list`
        );
        if (response.data.success) {
          this.districts = response.data.data;
        } else {
          $toast.error(response.data.message);
        }
      } catch (error) {
        $toast.error("Error fetching districts");
      }
    },

    // Fetch professions from the API
    async fetchProfessions() {
      const $toast = useToast({ position: "top" });
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/profession-list`
        );
        if (response.data.success) {
          this.professions = response.data.data;
        } else {
          $toast.error(response.data.message);
        }
      } catch (error) {
        $toast.error("Error fetching professions");
      }
    },

    // Handle form submission
    handleSubmit() {
      // Store form data in local storage
      localStorage.setItem("customerFormData", JSON.stringify(this.formData));
      // Navigate to the NomineeComponent route
      router.push({ name: "NomineeComponent" });
    },

    // Navigate to the previous step
    goBack() {
      this.$router.push({ name: "SignatureComponent" });
    },
  },
};
</script>

<style scoped>
/* Style for form and form buttons */
.form-body {
  margin-top: 5px;
}

.form-group {
  margin-bottom: 5px;
}
.form-control {
  font-size: 12px !important;
}

/* Styling for form buttons */
.form-row-last .btn {
  width: 100%;
}

blockquote {
  font-size: 11px !important;
}

/* Responsive design for smaller screens */
@media (max-width: 767px) {
  .form-left {
    margin-bottom: 20px;
  }
}
</style>
